<template>
  <div class="boolean field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div>
      <input
          type="checkbox"
          name="name"
          :checked="fieldValue || fieldConfig.defaultValue"
          @change="onChange"
      >
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  export default {
    name: "vJsonBooleanField",
    mixins: [
      JsonFieldMixin
    ],
    methods: {
      onChange(event) {
        this.updateValue(event.target.checked)
      }
    }
  }
</script>

<style scoped>
  input {
    width: auto;
  }
</style>
