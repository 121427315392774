import CardAccountDetailsIcon from 'icons/CardAccountDetails.vue'
import ChartLineIcon          from 'icons/ChartLine.vue'
import ChartScatterPlotIcon   from 'icons/ChartScatterPlot.vue'
import CloseBoxIcon           from 'icons/CloseBox.vue'
import CloudPrintIcon         from 'icons/CloudPrint.vue'
import EyeIcon                from 'icons/Eye.vue'
import EyeOffIcon             from 'icons/EyeOff.vue'
import FingerprintIcon        from 'icons/Fingerprint.vue'
import FingerprintOffIcon     from 'icons/FingerprintOff.vue'
import HelpIcon               from 'icons/Help.vue'
import HelpCircleIcon         from 'icons/HelpCircle.vue'
import HelpCircleOutlineIcon  from 'icons/HelpCircleOutline.vue'
import LeadPencilIcon         from 'icons/LeadPencil.vue'
import LinkPlusIcon           from 'icons/LinkPlus.vue'
import NewspaperPlusIcon      from 'icons/NewspaperPlus.vue'
import NewspaperVariantIcon   from 'icons/NewspaperVariant.vue'
import PlayIcon               from 'icons/Play.vue'
import TableEyeIcon           from 'icons/TableEye.vue'
import TextBoxOutlineIcon     from 'icons/TextBoxOutline.vue'
import TextBoxPlusIcon        from 'icons/TextBoxPlus.vue'
import TrashCanOutlineIcon    from 'icons/TrashCanOutline.vue'
import TrashCanIcon           from 'icons/TrashCan.vue'
import ViewGridPlusIcon       from 'icons/ViewGridPlus.vue'
import WebIcon                from 'icons/Web.vue'

import Home         from './pages/Home'
import RepoDocument from './pages/RepoDocument'

import { Datetime } from 'vue-datetime'
import draggable    from 'vuedraggable'
import colorPicker  from '@caohenghu/vue-colorpicker'

import vAPACitation                        from './Publications/vAPACitation'
import vAuth                        from './vAuth'
import vButton                        from './vButton'
import vCollection                  from './DocumentCollection/vCollection'
import vCollectionList              from './DocumentCollection/vCollectionList'
import vCollections                 from './DocumentCollection/vCollections'
import vColumnDocument              from './pages/hope/vColumnDocument'
import vDeleteDocumentButton  from './DocumentCollection/vDeleteDocumentButton'
import vDeleteVersionButton   from './DocumentCollection/vDeleteVersionButton'
import vDocument              from './DocumentCollection/md/vDocument'
import vEditingDocumentsInfo  from './DocumentCollection/vEditingDocumentsInfo'
import vImage                       from './vImage'
import vInput                       from './vInput'
import vJsonObject                  from './JSONObject/vJsonObject'
import vLoading                     from './vLoading'
import vLabeledIconButton                        from './vLabeledIconButton'
import vList                        from './vList'
import vMarkdown         from './MarkdownIt/vMarkdown'
import vMarkdownContent  from './MarkdownIt/editor/content/vMarkdownContent'
import vMarkdownDocument from './MarkdownIt/editor/document/vMarkdownDocument'
import vNameNewItem       from './DocumentCollection/vNameNewItem'
import vNavigation       from './vNavigation'
import vNewsFeed       from './pages/hope/vNewsFeed'
import vNewsTeaser       from './pages/hope/vNewsTeaser'
import vNotFound                    from './vNotFound'
import vPeople                      from './vPeople'
import vPerson                      from './vPerson'
import vPublications                from './Publications/vPublications'
import vRepoFile                    from './vRepoFile'
import vReportBug                   from './vReportBug'
import vSortCollectionDocumentsInfo from './DocumentCollection/vSortCollectionDocumentsInfo'
import vSortDocumentSequence from './DocumentCollection/vSortDocumentSequence'
import vUrlSafeStringInput         from './vUrlSafeStringInput'
import vUserRole             from './vUserRole'

export default {
  CardAccountDetailsIcon,
  ChartLineIcon,
  ChartScatterPlotIcon,
  CloseBoxIcon,
  CloudPrintIcon,
  EyeIcon,
  EyeOffIcon,
  FingerprintIcon,
  FingerprintOffIcon,
  HelpIcon,
  HelpCircleIcon,
  HelpCircleOutlineIcon,
  LeadPencilIcon,
  LinkPlusIcon,
  NewspaperPlusIcon,
  NewspaperVariantIcon,
  PlayIcon,
  TableEyeIcon,
  TextBoxOutlineIcon,
  TextBoxPlusIcon,
  TrashCanOutlineIcon,
  TrashCanIcon,
  ViewGridPlusIcon,
  WebIcon,

  Home,
  RepoDocument,

  Datetime,
  draggable,
  colorPicker,

  vAPACitation,
  vAuth,
  vButton,
  vCollection,
  vCollectionList,
  vCollections,
  vColumnDocument,
  vDeleteDocumentButton,
  vDeleteVersionButton,
  vDocument,
  vEditingDocumentsInfo,
  vImage,
  vInput,
  vJsonObject,
  vLabeledIconButton,
  vList,
  vLoading,
  vMarkdown,
  vMarkdownContent,
  vMarkdownDocument,
  vNameNewItem,
  vNavigation,
  vNewsFeed,
  vNewsTeaser,
  vNotFound,
  vPeople,
  vPerson,
  vPublications,
  vRepoFile,
  vReportBug,
  vSortCollectionDocumentsInfo,
  vSortDocumentSequence,
  vUrlSafeStringInput,
  vUserRole,
}
