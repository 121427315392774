<template>
  <div class="percentage field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div class="control">
      <input
          ref="range"
          type="range"
          name="name"
          :min="min"
          :max="max"
          :value="value"
          @input="onChange"
      >
      <div
          class="postfix"
          :style="postFixStyle"
      >
        <span :style="labelStyle">
          {{labelText}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  export default {
    name: "vJsonRangeField.vue",
    mixins: [
      JsonFieldMixin
    ],
    data () {
      return {
        isUnchanged: true
      }
    },
    computed: {
      stepMultiplier() {
        return this.fieldConfig.stepMultiplier || 1
      },
      min() {
        return this.fieldConfig.min !== undefined ? this.fieldConfig.min : 0
      },
      max() {
        let value = this.fieldConfig.max !== undefined ? this.fieldConfig.max : 100
        value = value * this.stepMultiplier
        return value
      },
      median() {
        return (this.max - this.min) / 2
      },
      value() {
        const value = (
            this.isUnchanged && (
              this.fieldConfig.value ??
              this.fieldConfig.defaultValue ??
              this.median
            )
          ) ??
          this.fieldValue * this.stepMultiplier
        return value
      },
      labelText() {
        let text
        if (this.fieldValue) {
          text = this.fieldValue + this.labelSuffix
        } else {
          text = '< >'
        }
        return text
      },
      labelSuffix() {
        return this.fieldConfig.labelSuffix || ''
      },
      labelStyle() {
        return {
          paddingLeft: `${this.proportionalValue}%`
        }
      },
      postFixStyle() {
        return {
          paddingRight: `${ this.labelText.length / 2 }em`
        }
      },
      proportionalValue() {
        const dTotal = this.max - this.min
        const dValue = this.value - this.min

        let proportion = dValue / dTotal * 100

        return Math.round( proportion)
      }
    },
    methods: {
      onChange(event) {
        this.isUnchanged = null
        this.updateValue(event.target.value / this.stepMultiplier)
      }
    }
  }
</script>

<style scoped>
  .field {
    display: grid;
    grid-template-columns: 10rem auto;
    width: 100%;
  }
  .control {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .control > * {
    width: 100%;
  }
  .postfix {
    position: relative;
  }
</style>
