<template>
  <div class="permalink field">
    <label for="name">
      Permalink
      <span
          @click="selectFieldType('Doi')"
          :class="{selected: selected  === 'Doi'}"
      >doi</span> /
      <span
          @click="selectFieldType('Arxiv')"
          :class="{selected: selected  === 'Arxiv'}"
      >arxiv</span>
    </label>
    <component
        :is="selectedFieldType"
        :collectionName="collectionName"
        :documentId="documentId"
        :objectPath="objectPath"
        :fieldConfig="fieldConfig"
    ></component>
  </div>
</template>

<script>
import vJsonDoiField from "@/components/JSONObject/vJsonDoiField"
import vJsonArxivField from "@/components/JSONObject/vJsonArxivField"

export default {
  name: "vJsonPermalinkField",
  components: {
    vJsonDoiField,
    vJsonArxivField,
  },
  props: {
    collectionName: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    fieldConfig: {
      type: Object,
      required: true
    },
    objectPath: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      selected: 'Doi',
    }
  },
  computed: {
    selectedFieldType() {
      return `vJson${this.selected}Field`
    }
  },
  methods: {
    selectFieldType(typeName) {
      this.selected = typeName
    }
  }
}
</script>

<style scoped>
  .selected {
    background-color: greenyellow;
  }
</style>
