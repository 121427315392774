<template>
  <div class="collectionNames">

    <p>
      <em>Typed document</em> collection names:
    </p>

    <div
      v-for="(name, index) in collectionNames"
      :key="index"
      @click="$router.push(`/documents/${name}`)">
      <a>{{name}}</a>
    </div>

    <div
        class="actions"
        v-if="user"
    >
      <vLabeledIconButton
          v-if="user"
          label="Create a New Collection"
          icon="NewspaperPlusIcon"
          :iconSizePx="96"
          fontSize="1.2rem"
          @onClick="askForCollectionName"
      />

      <vNameNewItem
          v-if="collectionNameInputIsVisible"
          disableTriggerButton="true"
          heading="Name the new <strong>Collection</strong> (step 1/2)"
          buttonLabel="Name the new Document Collection"
          :takenNames="collectionNames"
          :isDisabled="documentNameInputIsVisible"
          typeName="Collection"
          @onValidName="getCollectionNameAndAskForFirstDocumentName"
      ></vNameNewItem>
      <vNameNewItem
          v-if="documentNameInputIsVisible"
          disableTriggerButton="true"
          heading="Name the first <strong>Document</strong> (step 2/2)"
          buttonLabel="Name the first Document"
          :reservedNames="reservedVersionNames"
          :takenNames="[]"
          typeName="Document"
          @onValidName="getDocumentNameAndCreateDocument"
      ></vNameNewItem>

    </div>
  </div>
</template>

<script>
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: "vCollectionList",
    mixins: [
      DocumentCollectionsMixin
    ],
    props: {
      collectionType: {
        type: String,
        default: 'md',
        validator: (val) => ['md', 'json', 'csv'].includes(val)
      }
    },
    data() {
      return {
        collectionNameInputIsVisible: false,
        documentNameInputIsVisible: false,
        newCollectionName: '',
        newDocumentName: '',
      }
    },
    computed: {
      collectionNames() {
        return Object.keys(this.$store.state.collections)
      },
      defaultContent() {
        const improvisedTitle = this.newDocumentName.replaceAll('_', ' ')
        return `# ${ improvisedTitle }`
      },
    },
    mounted() {
      this.loadCollections()
    },
    methods: {
      askForCollectionName() {
        this.collectionNameInputIsVisible = !!this.user
      },
      createCollectionWithDocument() {
        this.createDocument({
          collectionName: this.newCollectionName,
          name: this.newDocumentName
        })
        .then(() => {
          const collectionName = this.newCollectionName
          this.$router.push('documents/' + collectionName)
        })
      },
      getCollectionNameAndAskForFirstDocumentName(collectionName) {
        this.newCollectionName = collectionName
        this.documentNameInputIsVisible = true
      },
      getDocumentNameAndCreateDocument(versionName) {
        this.newDocumentName = versionName
        this.createCollectionWithDocument()
      },
      resetDialogs() {
        this.collectionNameInputIsVisible = false
        this.documentNameInputIsVisible = false
        this.validationErrorMessage = false
        this.newCollectionName = ''
        this.newDocumentName = ''
      },
    }
  }
</script>

<style scoped>
  a {
    color: #0c1e29;
    text-decoration: underline!important;
    font-weight: 900;
  }
</style>
