<template>
  <div class="mdIntro info">
    <h2>Editing Markdown Documents</h2>
    <p>
      Documents are written in markdown.
      This allows you to create content with:
    </p>
    <ul>
      <li>plain text</li>
      <li>
        <a href="https://markdown-it.github.io/" target="_blank">markdown</a>
      </li>
      <li>
        <a href="https://www.learn-html.org/en/Basic_Elements" target="_blank">html</a>
      </li>
    </ul>
    <p>
      You can link to documents from other documents. The easiest way is to use the markdown link annotation:
    </p>
    <pre>[visible text](link url)</pre>
    <p>
      The link urls for each article are copied automatically by clicking on the link symbol ( <linkPlusIcon/> ) next to headings in the document.
    </p>
    <hr>
    <p>
      The easiest way to embed images is to use their <em>url</em> like so:
    </p>
    <pre>
      ![image](<em>image url</em>)
    </pre>
    <p>
      You can also upload images to a github repository and link to it. An Example:
    </p>
    <pre>
      ![image](<em>https://raw.githubusercontent.com/centre-for-humanities-computing/HOPE_website_content/master/images/myUploadedImage.jpg</em>)
    </pre>
  </div>
</template>

<script>
  export default {
    name: "EditingDocumentsIntro",
    data () {
      return {
        infoRequested: null,
      }
    },
    computed: {

    }

  }
</script>

<style scoped>
  .mdIntro {
    padding: 1rem;
  }
  li {
    cursor: pointer;
  }
  .info {
    background-color: #ebf0f4;
  }
</style>
