<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div class="home">

        <vDocument
          documentName="hopepageTitle"
        ></vDocument>

        <div
          v-if="user"
          class="labeledButton"
        >
          <div
            @click="createNewTeaser"
            class="flex-c center icon6x addTeaserIcon">
            <ViewGridPlusIcon></ViewGridPlusIcon>
          </div>

        </div>

        <div
          v-if="reportTeasers.length"
          id="homeContent"
          class="content"
        >

          <div
            v-for="(document, index) in reportTeasers"
            :key="index"
            class="box theme--background-secondary"
          >
            <vDocument
                :documentId="document.id"
            ></vDocument>

          </div>

        </div>

        <p v-else>
          No home page teasers here yet...
        </p>

      </div>
    </div>
  </div>
</template>

<script>
  import DocumentCollectionMixin from '../../mixins/documentCollections/DocumentCollectionsMixin'
  export default {
    name: 'Home',
    mixins: [
      DocumentCollectionMixin
    ],
    props: {
      collectionName: {
        type: String,
        default: 'reportTeasers'
      }
    },
    computed: {
      defaultContent() {
        return this.$store.state.landingPagePlaceholder
      },
      reportTeasers: {
        get() {
          return this.documents
        }
      },
    },
    mounted() {
      this.loadLatestDocumentVersions(this.collectionName)
    },
    methods: {
      createNewTeaser() {
        this.createDocument({
          collectionName: this.collectionName,
          name: 'New Home Teaser'
        })
      }
    }
  }
</script>
<style scoped>
  .home {
    height: 100%;
  }
  .content {
    display: grid;
    min-width: 640px;
    max-width: 1600px;
    grid-template-columns: 1fr 1fr;
  }
  .content > * {
    margin: 4px;
  }
  .addTeaserIcon {
    transform: scale(-1, -1)
  }
</style>
