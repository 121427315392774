<template>
  <div class="color field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div>
      <color-picker
        class="colorPicker"
        theme="light"
        :color="fieldValue"
        :sucker-hide="false"
        :sucker-canvas="null"
        :sucker-area="[]"
        @changeColor="onChange"
      />
      <div
          class="complaint"
          v-for="(error, index) in complaints"
          :key="index"
      >
        <span class="error">{{error}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  export default {
    name: "vJsonColorField",
    mixins: [
      JsonFieldMixin
    ],
    props: {
      checks: {
        type: Array,
        default: () => [],
        validator(checks) {
          return checks.filter((check) => check.validator && check.complaint).length === checks.length
        }
      }
    },
    methods: {
      onChange(color) {
        const { r, g, b, a } = color.rgba
        this.updateValue(`rgba(${r}, ${g}, ${b}, ${a})`)
      },
    }
  }
</script>

<style scoped>
  .colorPicker {
    width: 216px!important;
  }
</style>
