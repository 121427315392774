<template>
  <div class="collection flex-c center document">
    <div
        v-if="user"
        class="article"
    >
      <vSortDocumentSequence
        :collectionName="collectionName"
        :documentList="documentList"
        :isReverse="isReversed"
      ></vSortDocumentSequence>

      <div class="actions flex-c center">
        <div
          @click="showMdIntro = !showMdIntro"
          class="icon icon2x">
          <HelpCircleIcon></HelpCircleIcon>
        </div>
      </div>
      <vEditingDocumentsInfo v-if="showMdIntro"></vEditingDocumentsInfo>

    </div>
    <h1>The <strong>{{collectionName}}</strong> Collection</h1>

    <vLabeledIconButton
        v-if="user"
        label="Create a New Document"
        icon="NewspaperPlusIcon"
        :iconSizePx="96"
        fontSize="1.2rem"
        @onClick="askForDocumentName"
    />
    <vNameNewItem
        v-if="documentNameInputIsVisible"
        disableTriggerButton="true"
        heading="Name the new <strong>Document</strong>"
        buttonLabel="Name the new Document"
        :takenNames="documentNames"
        typeName="Document"
        @onValidName="createNewDocument"
    ></vNameNewItem>


    <div class="documents">

      <div class="list">
        <div v-if="documents">

          <div
            class="flex center"
            v-for="document in documentList"
            :key="document.id"
          >

            <component
              :is="getDocumentComponentName(document)"
              :collectionName="document.collectionName"
              :documentId="document.id"
            ></component>

          </div>

        </div>
        <div v-else>

          <p>
            There are no documents in collection "{{collectionName}}"
          </p>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import vAddDocumentToCollection from "./vAddDocumentToCollection"
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"
  import titleToId                from "@/components/MarkdownIt/lib/titleToId"

  export default {
    name: "vCollection",
    components: {
      vAddDocumentToCollection
    },
    mixins: [
      DocumentCollectionsMixin,
    ],
    props: {
      type: {
        type: String,
        validator: (val) => ['md', 'json', 'csv'].includes(val)
      },
    },
    data() {
      return {
        documentNameInputIsVisible: false,
        newDocumentName: '',
        reversedCollectionNames: ['news', 'reports'],
        showMdIntro: false,
      }
    },
    computed: {
      defaultTitle() {
        return `New Document "${ this.newDocumentName }"`
      },
      defaultContent() {
        return `# ${this.defaultTitle}

no content yet`
      },
      documentElementId() {
        return titleToId(this.defaultTitle)
      },
      documentNames() {
        return this.documentList.map(d => d.name)
      },
      documentList() {
        return this.isReversed ? this.reverseSortedDocuments : this.sortedDocuments
      },
      isReversed() {
        return this.reversedCollectionNames.includes(this.collectionName)
      },
    },
    methods: {
      askForDocumentName() {
        this.documentNameInputIsVisible = !!this.user
      },
      createNewDocument(documentName) {
        this.newDocumentName = documentName
        this.createDocument({
            collectionName: this.collectionName,
            name: documentName,
          }).then(() => {
            this.documentNameInputIsVisible = false
            window.scrollTo(0,document.body.scrollHeight)
            this.newDocumentName = ''
        })
        // http://localhost:8082/#/documents/newColl#New%20Document%20%E2%80%9ClastDoc%E2%80%9D
      },
      getDocumentComponentName(document) {
        let name = 'vDocument'
        if (document.type === 'json') {
          switch (document.collectionName) {
            case 'people':
              name = 'vPerson'
              break;
            case 'publications':
              name = 'vAPACitation'
              break;
            // todo: put homeTeasers into self-styled component
          }
        }
        return name
      },
    }
  }
</script>

<style scoped>
  .collection {
    text-align: left;
  }
  .item:not(:last-child) {
    margin-bottom: 2px;
  }
  .faded {
    opacity: 0.6;
  }
  .documents {
    display: flex;
    flex-direction: column;
  }
</style>
