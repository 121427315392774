<template>
  <div class="template">
    <div
        v-if="user"
        class="actions">
      <vDeleteDocumentButton
          @onConfirmed="deleteDocument"
      />
    </div>
    <div
        class="documentColumns"
        :style="columnsStyle"
    >
      <div
          class="column"
          v-for="(column, index) in columns"
          :key="index"
      >
        <vMarkdownContent
            :value="column.content"
            :enableEditor="true"
            @onChange="updateContent($event, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: "vColumnDocument",
    mixins: [
      DocumentCollectionsMixin,
    ],
    props: {
      collectionName: {
        type: String,
        default: 'hopePageTeasers'
      },
      documentName: {
        type: String,
        required: true
      }
    },
    computed: {
      columns() {
        return this.parsed
      },
      columnsStyle() {
        let columns = '1fr '.repeat(this.columns.length).trim()
        return {gridTemplateColumns: columns}
      },
    },
    methods: {
      updateContent(newValue, index) {
        const content = [
            ...this.parsed
        ]
        content[index] = {"content": newValue}
        this.patchContent(content)
      }
    }
  }
</script>

<style scoped lang="scss">
  .template {
    .actions {
      display: flex;
      width: 100%;
      justify-content: end;
    }
  }
  .documentColumns {
    height: 100%;
    width: 100%;
    display: grid;
    grid-column-gap: 1rem;
    div {
      width: 100%;
    }
  }
</style>
