<template>
  <div class="document">
    <div
      class="info"
      v-if="user"
    >
      <div class="actions">
        <div class="spacer"></div>
        <div
          @click="showInfo=!showInfo"
          class="helpButton">
          <div class="icon icon2x">
            <HelpCircleIcon v-if="showInfo"></HelpCircleIcon>
            <HelpCircleOutlineIcon v-else></HelpCircleOutlineIcon>
          </div>
        </div>
      </div>
      <vEditingDocumentsInfo v-if="showInfo"></vEditingDocumentsInfo>
    </div>
    <div v-if="document">

      <article
        :class="{editing: showInput}"
        :style="documentStyle"
      >
        <vMarkdownDocument
            :versionAlias="versionAlias"
            :documentId="documentId"
            :documentName="documentName"
            :collectionName="collectionName"
            :showEditor="canEdit && showInput"
        ></vMarkdownDocument>

        <div class="icon icon2x">
          <div
            v-if="canEdit"
            class="actions"
          >

            <div class="toggle">
              <LeadPencilIcon
                v-if="canEdit && !showInput"
                class="primary"
                title="edit content"
                @click="showInput = true"
              ></LeadPencilIcon>
              <EyeOffIcon
                v-if="canEdit && showInput"
                class="primary"
                title="hide editor"
                @click="hideEditingComponents"
              ></EyeOffIcon>
            </div>

            <vDeleteDocumentButton
              v-if="user && !otherVersionsExist"
              @onConfirmed="deleteDocument"
            ></vDeleteDocumentButton>

            <vDeleteVersionButton
                v-if="user && otherVersionsExist"
                :onConfirmed="deleteVersion"
            ></vDeleteVersionButton>

          </div>
        </div>

      </article>

    </div>
    <div v-else>

      <p class="danger">
        {{ missingDocumentMessage }}
      </p>
      <vLabeledIconButton
          v-if="user && documentIsReferencedByNames"
          :label="createDocumentButtonLabel"
          icon="LeadPencilIcon"
          :iconSizePx="64"
          fontSize="1.2rem"
          @onClick="createNewDocument"
      />

    </div>

  </div>
</template>

<script>

  import { getMissingDocumentMessage } from "@/components/DocumentCollection/lib/messages"
  import DocumentCollectionsMixin            from "@/mixins/documentCollections/DocumentCollectionsMixin"
  import { DELETE_DOCUMENT, DELETE_VERSION } from "@/store/graph/types"

  export default {
    name: "vDocument",
    mixins: [
      DocumentCollectionsMixin,
    ],
    props: {
      collectionName: {
        type: String,
        default: 'pageTexts'
      },
      documentName: {
        type: String,
      },
      documentId: {
        type: String,
      },
      versionId: {
        type: String,
      },
      versionAlias: {
        type: String,
        default: 'latest',
      },
    },
    data() {
      return {
        requestedDocumentId: '',
        showInput: false,
        showInfo: false
      }
    },
    computed: {
      createDocumentButtonLabel() {
        return `Create document "${this.documentName}" now`
      },
      canEdit() {
        return !!this.user
      },
      collection() {
        let collection
        if (this.collectionName) {
          collection = Object.values(this.$store.state.documents)
              .filter(document => document.collectionName === this.collectionName)
        }
        return collection
      },
      defaultContent: {
        get() {
          return `# Title

  content here...`
        }
      },
      documentIsReferencedByNames() {
        return this.collectionName && this.documentName
      },
      documentStyle() {
        let gridStyle = '1fr'
        if (this.canEdit) {
          gridStyle += ' 2rem'
        }
        return {
          gridTemplateColumns: gridStyle
        }
      },
      missingDocumentMessage() {
        return getMissingDocumentMessage({
          userIsPrivileged: this.user,
          documentId: this.documentId,
          collectionName: this.collectionName,
          documentName: this.documentName
        })
      },
      otherVersionsExist() { return this.versions?.length > 1 },
    },
    mounted() {
      this.loadDocument({
        collectionName: this.collectionName,
        documentName: this.documentName
      })
      this.$nextTick(() => {

        this.showOnlyRouteHashArticle(this.$route.hash)

      })
    },
    methods: {
      createNewDocument() {
        this.createDocument({
          collectionName: this.collectionName,
          name: this.documentName,
        })
      },
      deleteDocument() {

        this.$store.dispatch(DELETE_DOCUMENT, {
          documentId: this.document.id
        })
        .then(() => {
          this.$router.push(`/documents/${this.collectionName}`)
        })
      },
      deleteVersion() {
        this.$router.push(`/reports/${this.documentName}`)
        this.$store.dispatch(DELETE_VERSION, {
          versionId: this.versionId ?? this.version.id
        })
      },
      getFirstPageText(el) {
        if (!el.classList.contains('pageText')) {
          const parent = el.parentElement
          if (parent) {
            el = this.getFirstPageText(parent)
          }
        }
        return el
      },
      hideEditingComponents() {
        this.showInfo = false
        this.showInput = false
      },
      hideOtherArticles() { // todo: review scope
        const articles = document.getElementsByClassName('pageText')

        for (let i = 0; i < articles.length; i++) {
          const article = articles[i]

          if (article.getAttribute('id') !== this.requestedDocumentId) {
            article.style.display = 'none'
          }

        }
      },
      showAllArticles() { // todo: review scope
        const articles = document.getElementsByClassName('pageText')

        for (let i = 0; i < articles.length; i++) {
          const article = articles[i]

          article.style.display = 'block'

        }
      },
      showOnlyRouteHashArticle(hash) { // todo: redo with this.aliasDocumentIdMap?
        if (hash) {

          const hashedHeading = document.getElementById(this.$route.hash.substring(1))

          if (hashedHeading) {
            const requestedArticle = this.getFirstPageText(hashedHeading)

            this.requestedDocumentId = requestedArticle.getAttribute('id')
            console.log(this.requestedDocumentId)

            if (!this.user) {

              this.hideOtherArticles()

            } else {

              this.showAllArticles()
              requestedArticle.scrollIntoView()

            }
          }
        }
      },
    },
  }
</script>

<style scoped>
  .document {
    text-align: left;
    /*padding: 1rem;*/
    margin-top: 1rem;
  }
  article {
    display: grid;
    grid-template-columns: 1fr 2em;
    padding: 0;
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .info .actions {
    width: 100%;
    height: min(90%, 6rem);
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .danger {
    color: darkred;
  }
  .image .frame,
  p {
    max-width: min(100%, 200rem)!important;
  }
</style>
