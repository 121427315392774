<template>
  <p>
    Change the listing sequence of documents in "{{collectionName}}" by dragging their ids in this list
    (documents below will sort accordingly):
  </p>
</template>

<script>
  export default {
    name: "vSortCollectionDocumentsInfo",
    props: {
      collectionName: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
