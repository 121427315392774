<template>
  <div
    class="markdownDocument"
  >
    <div
        class="document"
        :class="{editing: showEditor}"
    >
      <vMarkdownInput
        class="in"
        v-if="showEditor"
        :versionAlias="versionAlias"
        :documentId="documentId"
        :documentName="documentName"
        :collectionName="collectionName"
        :defaultContent="defaultContent"
      ></vMarkdownInput>
      <vMarkdownOutput
        class="out"
        :versionAlias="versionAlias"
        :documentId="documentId"
        :documentName="documentName"
        :collectionName="collectionName"
        :defaultContent="defaultContent"
      ></vMarkdownOutput>

    </div>
    <div
        v-show="error"
        class="error">
      {{error}}
    </div>
  </div>
</template>

<script>
  import WarningInterceptor       from "../../lib/WarningInterceptor"
  import DocumentCollectionsMixin from '../../../../mixins/documentCollections/DocumentCollectionsMixin'
  import vMarkdownInput           from '../vMarkdownInput'
  import vMarkdownOutput          from '../vMarkdownOutput'
  export default {
    name: "vMarkdownDocument",
    components: {
      vMarkdownInput,
      vMarkdownOutput,
    },
    mixins: [
      DocumentCollectionsMixin
    ],
    props: {
      collectionName: {
        type: String,
        required: true
      },
      documentId: {
        type: String,
        required: true
      },
      defaultContent: {
        type: String
      },
      showEditor: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        error: '',
      }
    },
    watch: {
      content: function() {
        this.error = ''
      }
    },
    mounted() {
      WarningInterceptor.registerErrorCallback(this._uid, this.showError)
    },
    methods: {
      showError(errorMessage) {
        this.error = errorMessage
        //this.flashError(escapeHTML(errorMessage))
        //console.log('error', errorMessage)
      }
    },

  }
</script>

<style scoped>
  .markdownDocument {
    text-align: left;
  }
  .document {
    display: grid;
    grid-template-columns: auto;
    text-align: left;
    background-color: transparent;
  }
  .document.editing {
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  .document.editing .out {
    background-color: transparent;
    height: 100%;
    margin-left: 1rem;
  }
  .document.editing .in {
    background-color: white;
    font-size: 100%;
    width: 99%;
    height: 100%;
    max-width: 99%;
    min-width: 50%;
    min-height: 2rem;
    margin-right: 1rem;
  }
  .error {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #fd7c7c;
    color: black;
    font-family: monospace;
    white-space: pre-wrap;
  }
</style>
