<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div class="hope">

        <vDocument
            collectionName="pageTexts"
            documentName="hopepageTitle"
        />

        <div class="content">
          <div class="left">

            <vDocumentTeasers :collectionName="collectionName"/>

          </div>
          <div class="right">
            <TwitterFeed></TwitterFeed>
            <hr>
            <h2>News</h2>
            <vNewsFeed></vNewsFeed>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vDocumentTeasers from './hope/vDocumentTeasers'
import TwitterFeed from '../TwitterFeed'

export default {
  name: "Hope",
  components: {
    TwitterFeed,
    vDocumentTeasers,
  },
  props: {
    collectionName: {
      type: String,
      default: 'hopePageTeasers'
    }
  },
  data() {
    return {
      sectionNames: [
        'reports',
        'news',
        'events',
        'publications',
      ]
    }
  },
}
</script>

<style scoped lang="scss">
.hope {
  height: 100%;
  min-width: 640px;
  max-width: 1600px;

  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr minmax(500px, 1fr);
    grid-column-gap: 1rem;

    .left {

    }

    .right {
      background-color: whitesmoke;
      padding: 0 0.5rem;
    }
  }
}

.content > * {
  margin: 4px;
}

.full-width {
  min-width: 100%;
}
</style>
