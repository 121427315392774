<template>
  <div class="text field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div>
      <input
          type="text"
          name="name"
          :value="fieldValue || fieldConfig.defaultValue"
          @keyup="onKey"
      >
      <div
          class="complaint"
          v-for="(error, index) in complaints"
          :key="index"
      >
        <span class="error">{{error}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  export default {
    name: "vJsonTextField",
    mixins: [
      JsonFieldMixin
    ],
    props: {
      checks: {
        type: Array,
        default: () => [],
        validator(checks) {
          return checks.filter((check) => check.validator && check.complaint).length === checks.length
        }
      }
    }
  }
</script>

<style scoped>

</style>
