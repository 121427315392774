<template>
  <div class="newsFeed">
    <vButton
        class="button fw"
        v-if="user"
        label="Create new News Item"
        :onClick="createNewsItem"
    />
    <div
      v-if="news.length"
      class="list"
    >

      <vNewsTeaser
          v-for="(item, index) in news"
          :key="index"
          :collectionName="collectionName"
          :documentName="item.name"
      ></vNewsTeaser>

    </div>
    <p
      v-else
      class="icon2x"
    >
      No news here yet...
    </p>

  </div>
</template>

<script>
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: "vNewsFeed",
    mixins: [
      DocumentCollectionsMixin
    ],
    props: {
      collectionName: {
        type: String,
        default: 'news'
      }
    },
    computed: {
      news: {
        get() {
          return this.reverseSortedDocuments
        }
      },
      defaultContent() {
        return `# New News Item\n\nparagraph...`
      }
    },
    mounted() {
      this.loadLatestDocumentVersions(this.collectionName)
    },
    methods: {
      createNewsItem() {
        this.createDocument({
          collectionName: 'news',
          name: `newsItem_${Date.now()}`,
        })
      },

    }
  }
</script>

<style scoped>
  .newsFeed {
    height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .list {
    max-width: 100%!important;
  }
  .news img,
  .news .image,
  .news .frame
  {
    margin: 0!important;
    padding: 0!important;
    width: 100%;
  }
  .hope .news h1 {
    margin-bottom: 0;
    pagging-bottom: 0;
  }
</style>
