<template>
  <div class="sorter">

    <div
        v-if="documents && documents.length > 1"
        class="sortable">

      <vSortCollectionDocumentsInfo :collectionName="collectionName"></vSortCollectionDocumentsInfo>

      <draggable v-model="documentAliases">
        <transition-group>
          <div
              v-for="alias in documentAliases"
              :key="alias"
              class="item theme--background-secondary"
          >
            {{ documentList.find(d=>d.id === aliasDocumentIdMap[alias]).index }} {{alias}}
          </div>
        </transition-group>
      </draggable>
    </div>

  </div>
</template>

<script>
  import DocumentCollectionsMixin from '../../mixins/documentCollections/DocumentCollectionsMixin'
  import * as actions             from "@/store/graph/types"
  export default {
    name: "vSortDocumentSequence",
    mixins: [
      DocumentCollectionsMixin,
    ],
    props: {
      collectionName: {
        type: String,
        required: true
      },
      documentList: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        aliasDocumentIdMap: {},
      }
    },
    computed: {
      documentAliases: {
        get() {
          let aliases = ['CollectionMixin.documentAliases Error: missing component prop (Function) "getDocumentAlias"']

          if (this.getDocumentAlias) {
            aliases = this.mapDocumentAliases()
          }
          if (this.indexesAreReversed()) aliases.reverse()
          return aliases
        },
        set(aliasSequence) {
          let documentIdSequence = aliasSequence.map(alias => this.aliasDocumentIdMap[alias])
          if (this.indexesAreReversed()) documentIdSequence.reverse()
          this.updateDocumentSequence(documentIdSequence)
        }
      },
    },
    methods: {
      getDocumentAlias(document) {
        let alias = document.id
        let generator
        if (document.type === 'md') {
          generator = this.getFirstMarkdownTitleAlias
        } else if (document.type === 'json') {
          if (document.collectionName === 'people') {
            generator = this.getPersonFullNameAlias
          } else if (document.collectionName === 'publications') {
            generator = this.getPublicationTitleAlias
          }
        }
        alias = generator(document) ?? document.id
        return alias
      },
      getFirstMarkdownTitleAlias(document) {
        let firstTitleLine
        const markdown = this.getLatestContentByDocumentId(document.id)
        if (markdown) {
          const lines = markdown.trim().split('\n').map(l => l.trim())
          for (let l = 0; l < lines.length; l++) {
            const isHeading = /^#+\s\S+/m.test(lines[l])
            if (isHeading) {
              firstTitleLine = lines[l]
              break
            }
          }
        }
        return firstTitleLine
      },
      getPersonFullNameAlias(document) {
        const json = this.getLatestContentByDocumentId(document.id)
        const person = JSON.parse(json)
        return person.name
      },
      getPublicationTitleAlias(document) {
        const json = this.getLatestContentByDocumentId(document.id)
        const publication = JSON.parse(json)
        return publication.publicationTitle ?? publication.titleOfPage
      },
      indexesAreReversed() {
        return this.documentList[0].index > this.documentList[this.documentList.length -1].index
      },
      mapDocumentAliases() {
        this.aliasDocumentIdMap = {}
        const isReversed = this.indexesAreReversed()
        let aliasList
        for (let i = 0; i < this.documentList.length; i++) {
          const doc = this.documentList[i]
          let alias = this.getDocumentAlias(doc)
          const aliasIsAmbiguous = this.aliasDocumentIdMap[alias] !== undefined
          if (aliasIsAmbiguous) {
            alias = doc.id
          }
          this.aliasDocumentIdMap[alias] = doc.id
        }
        aliasList = Object.keys(this.aliasDocumentIdMap)
        if (isReversed) aliasList.reverse()
        return aliasList
      },
      updateDocumentSequence(documentIdSequence){
        this.$store.dispatch(actions.PUT_DOCUMENT_SERIES_SEQUENCE, {
          collectionName: this.collectionName,
          documentIdSequence
        })
      },
    },
  }
</script>

<style scoped>

</style>
