<template>
  <div class="reference">
    <vJsonObject
        :editing="user && editing"
        class="jsonObject"
        :class="{editing: editing}"
        :collectionName="collectionName"
        :documentId="documentId"
        :fields="fields"
    >
      <div class="rendered">
        <div
            v-if="user"
            class="actions"
        >
          <div
              class="icon"
              @click="editing=!editing"
          >
            <leadPencilIcon></leadPencilIcon>
          </div>
          <vDeleteDocumentButton
            @onConfirmed="deleteDocument"
          ></vDeleteDocumentButton>
        </div>
        <vMarkdown :md="citation"></vMarkdown>
      </div>
    </vJsonObject>
  </div>
</template>

<script>
  import JsonDocumentMixin        from '../../mixins/documentCollections/json/JsonDocumentMixin'
  //import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"
  export default {
    name: "vAPAWebPage",
    mixins: [
      //DocumentCollectionsMixin,
      JsonDocumentMixin
    ],
    data() {
      return {
        editing: false,
        notation: '$authors ($publicationDate). $siteName. _$articleTitle_. Retrieved $retrievalDate, from $pageUrl',
        fields: [
          {
            name: "Authors",
            type: 'Authors',
            required: true
          },
          {
            name: "Website Name",
            type: "text",
            required: true
          },
          {
            label: "Article Title",
            name: "publicationTitle",
            type: "text",
            required: true
          },
          {
            name: 'Publication Date',
            type: 'date',
            required: true
          },
          {
            name: "Page Url",
            type: "url",
            required: true
          },
          {
            name: 'Retrieval Date',
            type: 'date',
            required: true
          },
          {
            name: "Language",
            defaultValue: "en",
            type: "select",
            options: [
              'da',
              'en'
            ]
          },
        ],
        APADateFormat: 'YYYY, MMMM DD'
      }
    },
    computed: {
      reference: {
        get() {
          return this.object
        },
      },
      citation() {

        const authors = this.renderField(this.reference, 'Authors')

        const renderedPubDate = this.$date(this.reference['Publication Date']).format(this.APADateFormat)
        const publicationDate = this.renderField(this.reference, 'Publication Date', renderedPubDate)

        const siteName = this.renderField(this.reference, 'Website Name',)
        const articleTitle = this.renderField(this.reference, 'Article Title')

        const renderedRetDate = this.$date(this.reference['Retrieval Date']).format(this.APADateFormat)
        const retrievalDate = this.renderField(this.reference, 'Retrieval Date', renderedRetDate)

        const pageUrl = this.renderField(this.reference, 'Page Url')

        const citation = this.notation
          .replace(/\$authors/g, authors)
          .replace(/\$publicationDate/g, publicationDate)
          .replace(/\$siteName/g, siteName)
          .replace(/\$articleTitle/g, articleTitle)
          .replace(/\$retrievalDate/g, retrievalDate)
          .replace(/\$pageUrl/g, pageUrl)

        return citation
      },
    },
    methods: {
      deleteDocument() {
        this.deleteCollectionDocument(this.documentId)
      },
    }
  }
</script>

<style scoped>
  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>
