<template>
  <div
    class="action"
  >
    <div
      class="labeledButton">
      <div
        v-if="!showForm"
        @click="showForm = true"
        class="icon icon4x">
        <newspaperPlusIcon></newspaperPlusIcon>
      </div>
      <span @click="showForm = true">
            Add a Document
          </span>
    </div>

    <div
      v-if="user && collection && showForm"
      class="createDocument"
    >
      <h3>Create a new document in "{{collectionName}}"</h3>

      <p>
        First decide on the unique identifier for your new document. Edit the identifier in the input field until you are satisfied with the document document url below. Once the new document is created its identifier cannot be changed anymore
      </p>

      <input
        type="text"
        placeholder="Document_Identifier"
        :value.sync="newDocumentId"
        @change="updateNewDocumentId"
        @keyup="updateNewDocumentId"
      >

      <pre><span class="faded">{{collectionUrl}}/</span>{{encodeURIComponent(newDocumentId)}}</pre>

      <div
        v-if="user"
        class="labeledButton"
        :class="{disabled: !newIdIsUnique}">
        <div
          @click="createAndEditDocument"
          class="icon icon4x">
          <newspaperPlusIcon></newspaperPlusIcon>
        </div>
        <span @click="createAndEditDocument">
            Go to Document Editor
          </span>
      </div>
    </div>
  </div>
</template>

<script>
  import CollectionMixin     from "../../mixins/documentCollections/CollectionMixin"
  import MarkdownDocumentMixin from '../../mixins/documentCollections/markdown/MarkdownDocumentMixin'
  export default {
    name: "vAddDocumentToCollection",
    mixins: [
      CollectionMixin
    ],
    data() {
      return {
        showForm: false,
        value_newDocumentId: 'changeMe',
      }
    },
    computed: {
      baseUrl: {
        get() {
          return location.href.match(/^(\S+)\/#/)[1]
        }
      },
      collectionUrl: {
        get() {
          let collectionUrl
          if (this.collection) {
            collectionUrl = `${this.baseUrl}/#/documents/${this.collectionName}`
          }
          return collectionUrl
        }
      },
      linkUrl: {
        get() {
          let url = 'no collection, no link'
          if (this.collection) {
            url = `${this.collectionUrl}/${this.newDocumentId}`
          }
          return url
        }
      },
      newDocumentId: {
        get() {
          return decodeURIComponent(this.value_newDocumentId)
        },
        set(newVal) {
          this.value_newDocumentId = encodeURIComponent(newVal.trim())
        }
      },
      newIdIsUnique() {
        // todo: determine by alias. check this before creating docs
        return true
      }
    },
    methods: {
      createAndEditDocument(event) {
        if (this.newDocumentId) {
          // todo: create with alias as document id
          // todo: show error if already exists (POST error response)
          // todo: type selection
          MarkdownDocumentMixin.methods
            .createMarkdownDocument.bind(this)(
              event, {
              documentId: this.newDocumentId,
              index: this.documents.length,
              md: '# new document'
            })
            .then(this.openDocumentInEditor)
        }
      },
      openDocumentInEditor() {
        this.showForm = false
        this.$router.push(`/documents/${this.collectionName}/${this.newDocumentId}`)
      },
      updateNewDocumentId(event) {
        let alias = event.target.value.trim()

        const isUnique = this.documentAliases[alias] === undefined
        // todo: check if first markdown title is in aliases instead (call class methods)
        if (isUnique || alias === '') {
          this.newDocumentId = alias
        } else {
          console.log('not unque')

        }
      },
    }
  }
</script>

<style scoped>
  .disabled {
    opacity: 0.5
  }
</style>
