<template>
  <div class="collections">

    <h1>Document Collections</h1>

    <p>
      Documents always reside in a collection. There are two types of document collections in this app:
      <em>document collections</em> and <em>reports</em>. This page lists all collections.
    </p>
    <p>
      <em>Document collections</em> contain individual items, with very different content (Fx news items).
      When a visitor opens a link to a <em>document collection</em> all items will be displayed for browsing.
      All non-version-controlled editable content in this app is implemented as <em>typed documents</em>.
    </p>

    <p>
      <em>Reports</em> on the other hand are version controlled. Visitors will only see the latest version by default.
      The report view offers a dropdown to see older versions of the respective <em>report</em>.
      Each report version can also be accessed by permalink.
      Links to <em>reports</em> are featured on the <a @click="$router.push('/')">home page</a> and
      in the <a @click="$router.push('/collections')">list of collections</a> below.
    </p>

    <h3>Url Patterns</h3>

    <table>
      <tr>
        <th>resource</th>
        <th>url pattern</th>
      </tr>
      <tr>
        <td>a <em>typed document</em></td>
        <td>https://hope-project.dk/#/documents/$collectionName/$documentAlias</td>
      </tr>
      <tr>
        <td>the latest version of a <em>report</em></td>
        <td>https://hope-project.dk/#/reports/$reportName</td>
      </tr>
      <tr>
        <td>all versions of a <em>report</em></td>
        <td>https://hope-project.dk/#/reports/$reportName/versions</td>
      </tr>
      <tr>
        <td>permalink to a <em>report</em> version</td>
        <td>https://hope-project.dk/#/reports/$reportName/versions/$version</td>
      </tr>
    </table>

    <h3>Available Collections</h3>

    <p>
      Be aware that some collections have fixed destinations.
      For example documents of the collection "homeTeasers" will always be listed on the
      <a @click="$router.push('/')">home page</a> and "news" documents will be listed on the <a @click="$router.push('/news')">news page</a>.
    </p>

    <p>To edit a certain document, find it in its collection. The collections used on this site are listed below</p>

    <vCollectionList></vCollectionList>

  </div>
</template>

<script>
  export default {
    name: "vCollections",
  }
</script>

<style scoped>
  table {
    background-color: #ecf4fd;
    padding: .5rem;
  }
  table>tr:not(:last-child)>td {
    border-bottom: #1c4250 1px solid;
  }
</style>
