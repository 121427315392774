<template>
  <div class="timeline">
    <a class="twitter-timeline" data-height="450" href="https://twitter.com/interact_minds?ref_src=twsrc%5Etfw">
      Tweets by interact_minds
    </a>
  </div>
</template>

<script>
export default {
  name: "TwitterFeed",
  mounted() {
    const twitterWidgetUrl = 'https://platform.twitter.com/widgets.js'
    const twitterWidgetScript = document.createElement('script')
    twitterWidgetScript.setAttribute('src', twitterWidgetUrl)
    document.head.appendChild(twitterWidgetScript)
  }
}
</script>

<style scoped>

</style>