<template>
  <div class="date field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div>
      <datetime
          v-model="date"
          :auto="true"
          :format="luxorFormat"
          value-zone="GMT"
          zone="GMT"
          :max-datetime="maxDate"
      ></datetime>
      <div
          class="complaint"
          v-for="(error, index) in complaints"
          :key="index"
      >
        <span class="error">{{error}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  import { Settings } from 'luxon' // this dependency sucks (vue-datetime choice)

  export default {
    name: "vJsonDateField",
    mixins: [
      JsonFieldMixin
    ],
    data() {
      return {
        rtf: null,
        checks: [],
        luxorFormat: 'yyyy, MMMM d',
        APADateFormat: 'YYYY, MMMM D',
        maxDate: this.$date().toISOString(true)
      }
    },
    computed: {
      date: {
        get() {

          return this.APADateToISODate(this.fieldValue)

        },
        set(value) {

          value = this.ISODateToAPADate(value)

          this.updateValue(value)

        }
      }
    },
    created() {
      Settings.defaultLocale = 'da'
    },
    methods: {
      ISODateToAPADate(isoDateString) {
        return this.$date(isoDateString)
      },
      APADateToISODate(APADateString) {
        return this.$date(APADateString, this.APADateFormat).toISOString()
      },
      dismissTimeZone(iso) {
        return iso.replace(/[Z+-](\d{4}|\d{2}:\d{2)?$/i, '')
      }
    }
  }
</script>

<style scoped>
  .field {
    display: grid;
    grid-template-columns: 10rem auto;
  }
  input {
    width: 100%;
  }
</style>
