<template>
  <div
    class="deleteDocumentButton"
  >
    <div class="icon2x danger">
      <trashCanIcon
          label="Delete Document"
          class="danger"
          @click="showModal"
          :tip-position="tipPosition"
          :tip-alignment="tipAlignment"
      >
        Delete Document
      </trashCanIcon>
    </div>
    <transition
        name="modal"
        @close="visible=false"
    >
      <div
          class="modal-mask"
          v-if="visible"
      >
        <div
            class="modal-wrapper"
            @click="reset"
        >
          <div
            class="modal-container card cl"
            @click.stop
          >
            <h2>
              Confirm to Delete this Document
            </h2>
            <div>
              <p>There are no other versions of this. Please type '{{requiredInputValue}}' to confirm its deletion. This document will be irreversibly deleted.</p>
              <p>This action will auto-abort in {{secondsRemaining}} seconds</p>
              <div class="confirmActionDialog flex-r">
                <input
                  v-if="!confirmed"
                  type="text"
                  class="confirmationInput"
                  ref="input"
                  :placeholder="requiredInputValue"
                  v-model="reply"
                  :value.sync="reply"
                  :disabled="confirmed"
                  @keyup.esc="reset"
                  @keyup="checkReply"
                  @blur="checkReply"
                >
                <span v-else></span>
                <div class="icon4x">
                  <trashCanIcon
                      label="Delete Document"
                      class="actionButton danger"
                      :class="{disabled: disableActionButton}"
                      @click="emitConfirmed"
                      title="Delete Document"
                  >
                  </trashCanIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import TimeoutMixin from '../../mixins/TimeOutMixin'
  export default {
    name: "vDeleteDocumentButton",
    mixins: [
      TimeoutMixin
    ],
    props: {
      requiredInputValue: {
        type: String,
        default: 'delete'
      },
      tipPosition: {
        type: String,
        default: 'bottom'
      },
      tipAlignment: {
        type: String,
        default: 'center'
      },
      timeoutMS: {
        type: Number,
        default: 20000,
        validator(val) {
          return val > 5000
        }
      }
    },
    data() {
      return {
        reply: '',
        visible: false,
        invalidMessageText: 'This is not the required confirmation value',
        invalidMessage: '',
        disableActionButton: true,
        confirmed: false,
      }
    },
    methods: {
      showModal() {
        this.visible = true
        this.setTimeout(this.reset)
      },
      hideModal() {
        this.$emit('close')
        this.visible = false
      },
      checkReply($event) {
        const correctInput = $event.target.value === this.requiredInputValue
        if (correctInput) {
          this.confirm()
        } else {
          this.deny()
        }
        this.setTimeout(this.reset)
      },
      confirm() {
        this.confirmed = true
        this.invalidMessage = ''
        this.reply = ''
        this.disableActionButton = false
        this.cancelTimeout()
      },
      deny() {
        this.invalidMessage = this.invalidMessageText
        this.disableActionButton = true
        this.cancelTimeout()
      },
      reset() {
        this.reply = ''
        this.confirmed = false
        this.deny()
        this.hideModal()
        this.cancelTimeout()
        this.visible = false
      },
      emitConfirmed() {
        if (!this.disableActionButton) {
          this.$emit('onConfirmed')
          this.reset()
        }
      },
    }
  }
</script>

<style scoped>
  .deleteDocumentButton {
    font-size: 1rem;
  }
  .confirmActionDialog {
    display: grid;
    grid-template-columns: auto 3rem;
  }
  .confirmationInput {
    font-size: 3rem;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 40rem;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

</style>
