<template>
  <div class="json object property">
    <div
      class="editor theme--background-secondary"
      v-if="editing"
    >
      <div
        v-for="(field, index) in fields"
        :key="index"
      >
        <hr v-if="field.type === 'ruler'">
        <div
            v-else
            class="attribute"
        >
          <component
            :is="getAttributeComponentName(field.type)"
            :collectionName="collectionName"
            :documentId="documentId"
            :objectPath="objectPath"
            :fieldConfig="field"
          ></component>
        </div>
      </div>
    </div>

    <slot></slot>

  </div>
</template>

<script>
  import vJsonAuthorsField from './vJsonAuthorsField'
  import vJsonBooleanField from './vJsonBooleanField'
  import vJsonColorField from './vJsonColorField'
  import vJsonDateField from './vJsonDateField'
  import vJsonEmailField from './vJsonEmailField'
  import vJsonNumberField from './vJsonNumberField'
  import vJsonPermalinkField from './vJsonPermalinkField'
  import vJsonRangeField from './vJsonRangeField'
  import vJsonSelectField from './vJsonSelectField'
  import vJsonTextField from './vJsonTextField'
  import vJsonUrlField from './vJsonUrlField'

  import JsonDocumentMixin from '../../mixins/documentCollections/json/JsonDocumentMixin'

  export default {
    name: "vJsonObject",
    components: {
      vJsonAuthorsField,
      vJsonBooleanField,
      vJsonColorField,
      vJsonDateField,
      vJsonPermalinkField,
      vJsonEmailField,
      vJsonNumberField,
      vJsonRangeField,
      vJsonSelectField,
      vJsonTextField,
      vJsonUrlField,
    },
    mixins: [
      JsonDocumentMixin
    ],
    props: {
      type: {
        type: String,
        default: 'json',
        validator: (val) => val === 'json'
      },
      fields: {
        type: Array,
        required: true
      },
      editing: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      object() {
        return this.object
      },
    },
    methods: {
      getAttributeComponentName(type) {
        return `vJson${this.capitalize(type)}Field`
      },
      capitalize(str) {
        return `${str[0].toUpperCase()}${str.substring(1).toLowerCase()}`
      }
    }
  }
</script>

<style scoped>
  .editor {
    position: relative;
    min-width: min(90vw, 600px);
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .field {
    display: grid;
    grid-template-columns: 10rem auto;
  }
  input {
    width: 100%;
  }
</style>
