export const getMissingDocumentMessage = ({userIsPrivileged, documentId, collectionName, documentName}) => {
  return getMessage({userIsPrivileged, documentId, collectionName, documentName})
}

const getMessage = (details) => {
  const messageAlias = getMessageAlias(details)
  let message = `ERROR: message text for missing document not found {alias: ${messageAlias}`
  if (messages[messageAlias]) {
    message = messages[messageAlias](details)
  }
  return `
${message}
`
}
const getMessageAlias = ({userIsPrivileged, documentId, collectionName, documentName}) => {
  let alias = aliasTemplate
  if (documentId) {
    alias = alias.replace('$referenceType', 'ById')
  } else if (collectionName && documentName) {
    alias = alias.replace('$referenceType', 'ByNames')
  } else {
    if (!collectionName) {
      alias = alias.replace('$referenceType', 'NoCollectionName')
    } else {
      alias = alias.replace('$referenceType', 'NoDocumentName')
    }
  }
  if (userIsPrivileged) {
    alias += 'Privileged'
  }
  return alias
}
const aliasTemplate = 'doc$referenceType'

const messages = {
  docById: ({ documentId }) => `[missing document ${documentId}]`,
  docByNames: ({ collectionName, documentName }) => `[missing document "${collectionName}/${documentName}"]`,
  docNoCollectionName: () => '[missing content]',
  docNoDocumentName: () => '[missing content]',

  docByIdPrivileged: ({ documentId }) => `<missingDocument id=${documentId} />`,
  docByNamesPrivileged: ({ collectionName, documentName }) => `<missingDocument collectionName="${collectionName}" documentName="${documentName}" />`,
  docNoCollectionNamePrivileged: ({ documentName }) => `<incompleteDocumentReference collectionName=undefined documentName="${documentName}" />`,
  docNoDocumentNamePrivileged: ({ collectionName }) => `<incompleteDocumentReference collectionName="${collectionName}" documentName=undefined} />`,
}

