<template>
  <div class="select field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div>
      <select
          type="text"
          name="name"
          :value="fieldValue || fieldConfig.defaultValue"
          @change="onKey"
      >
        <option disabled value="Please select one"></option>
        <option
            v-for="(item, index) in fieldConfig.options"
            :key="index"
            :value="item"
        >
          {{item}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  export default {
    name: "vJsonSelectField",
    mixins: [
      JsonFieldMixin
    ],
  }
</script>

<style scoped>
  .field {
    display: grid;
    grid-template-columns: 10rem auto;
  }
</style>
