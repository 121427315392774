<template>
  <div class="number field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div>
      <input
          type="text"
          name="name"
          :value="fieldValue || fieldConfig.defaultValue"
          @keyup="onKey"
      >
      <div
          class="complaint"
          v-for="(error, index) in complaints"
          :key="index"
      >
        <span class="error">{{error}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  export default {
    name: "vJsonNumberField",
    mixins: [
      JsonFieldMixin
    ],
    data() {
      return {
        checks: [
          {
            validator: (val) => val.match(/^(?:-?(?:(?:\d+|\d*\.\d+)(?:[E|e][+|-]?\d+)?|Infinity))$/) !== null,
            complaint: `numbers must comply with one of the following notations:
  number:
    123
    1.23
    .123
    1e23
    .1e23

  with minus:
    -number
    1e-23
    -1e-23

  -Infinity
  Infinity
`
          }
        ],
      }
    },
  }
</script>

<style scoped>
  .field {
    display: grid;
    grid-template-columns: 10rem auto;
  }
  input {
    width: 100%;
  }
</style>
