<template>
  <div class="publications">
    <div
      v-if="user"
    >
      <div class="actions">
        <h2>Add APA Reference</h2>
        <div
          class="addButtons"
        >
          <div class="labeledButton flex-c center">
            <div class="icon6x">
              <CloudPrintIcon
                v-if="user"
                @click="addPreprintReference"
                :size="48"
                style="font-size: 48px!important;"
                title="Add a preprint publication reference"
              ></CloudPrintIcon>
            </div>
            <span @click="addPreprintReference">
              Article in Preprint
            </span>
          </div>
          <div class="labeledButton flex-c center">
            <div class="icon6x">
              <WebIcon
                v-if="user"
                @click="addWebReference"
                :size="48"
                style="font-size: 48px!important;"
                title="Add a Reference to a web page"
              ></WebIcon>
            </div>
            <span @click="addWebReference">
              Web Page
            </span>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div
        v-if="sortedDocuments && sortedDocuments.length"
        class="list"
    >
      <div
        v-for="(document, index) in sortedDocuments"
        :key="index"
        class="item"
      >
        <vAPACitation
            :collectionName="collectionName"
            :documentId="document.id"
        ></vAPACitation>
      </div>
    </div>
    <p v-else>
      No publications here yet...
    </p>

  </div>
</template>

<script>
  import vAPACitation                         from '../Publications/vAPACitation'
  import JsonCollectionMixin                  from '../../mixins/documentCollections/json/JsonCollectionMixin'
  import {
    getDefaultPreprintReference,
    getDefaultWWebReference
  }  from "@/mixins/documentCollections/json/lib/defaultContents"
  //import DocumentCollectionsMixin           from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: 'vPublications',
    components: {
      vAPACitation,
    },
    mixins: [
      //DocumentCollectionsMixin, // todo: absorb JsonCollectionMixin . CollectionMixin
      JsonCollectionMixin,
    ],
    props: {
      collectionName: {
        type: String,
        default: 'publications'
      },
      componentName: { // todo: leave logic within client
        type: String,
        default: 'vAPACitation',
      },
      listComponentName: { // todo: leave logic within client
        type: String,
        default: 'vPublications',
      }
    },
    methods: {
      addWebReference() {
        this.createJsonDocument(getDefaultWWebReference())
      },
      addPreprintReference() {
        this.createJsonDocument(getDefaultPreprintReference())
      },
    }
  }
</script>

<style scoped>
  .publications {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    min-width: 100%;
    height: 100%
  }
  .list {
    max-width: 38rem;
  }
  .list .item:not(:last-child) {
    border-bottom: grey 1px solid;
    margin-bottom: 1rem;
  }
  .addButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
