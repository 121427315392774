<template>
  <div
      class="nameNewItem"
      :class="{disabled: isDisabled}"
  >
    <vButton
        v-if="!showDialog"
        :label="buttonLabel"
        :disabled="isDisabled"
        :onClick="showNameInput"
    />

    <div v-if="showDialog">

      <h1 v-html="title"></h1>
      <p
          v-if="showImmutableNameWarning"
          class="danger message p1"
      >
        <em>{{ itemTypeNamePlural | capitalize }}</em> cannot be renamed after creation.
      </p>
      <p
          v-if="nameIsTakenError"
          class="danger message p1"
      >
        Another <em>{{ itemTypeName | decapitalize }}</em> with the name {{ newName }} already exists. Please find another name.
      </p>
      <vUrlSafeStringInput
          :placeholder="placeholder"
          :showCharRange="true"
          :isDisabled="isDisabled"
          :isConfirmed="newNameIsConfirmed"
          @onChange="evaluateName"
      ></vUrlSafeStringInput>

    </div>
  </div>
</template>

<script>
import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"
import { isArrayOfStrings }              from "../../../../lib/getVariableType"
import filters from  '../../filters'

export default {
  name: "vNameNewItem",
  filters,
  mixins: [
    DocumentCollectionsMixin,
  ],
  props: {
    buttonLabel: {
      type: String,
      default: 'Create Item'
    },
    disableTriggerButton: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    showImmutableNameWarning: {
      type: Boolean,
      default: true
    },
    reservedNames: {
      type: Array,
      default: () => [],
      validate: isArrayOfStrings
    },
    takenNames: {
      type:  Array,
      default: () => [],
      validate: isArrayOfStrings
    },
    typeName: {
      type: String,
      default: "Item",
      validate: typeName => ['Collection', 'Document', 'Version'].includes(typeName)
    },
    typeNamePlural: {
      type: String,
    }
  },
  data() {
    return {
      newName: '',
      nameInputIsVisible: false,
      nameIsTakenError: false,
      newNameIsConfirmed: false,
    }
  },
  computed: {
    placeholder() { return `New ${ filters.capitalize(this.typeName) } Name` },
    itemTypeName() { return this.typeName },
    itemTypeNamePlural() { return this.typeNamePlural ?? this.typeName + 's' },
    showDialog() { return this.disableTriggerButton || this.nameInputIsVisible },
    title() {
      let defaultTitle = `Create a new <strong>${ filters.capitalize(this.itemTypeName) }</strong>`
      return this.heading ?? defaultTitle
    },
  },
  methods: {
    assertNameIsNotReserved(name) {
      return !this.reservedNames.includes(name)
    },
    assertNameIsUnique(name) {
      return !this.takenNames.includes(name)
    },
    assertNameIsValid(name) {
      return this.assertNameIsNotReserved(name) && this.assertNameIsUnique(name)
    },
    confirmNewName(name) {
      this.$emit('onValidName', name)
      this.newNameIsConfirmed = true
      this.nameInputIsVisible = false
      this.newName = ''
    },
    evaluateName(name) {
      this.newName = name
      if (this.assertNameIsValid(name)) {
        this.confirmNewName(name)
      } else {
        this.nameIsTakenError = true
      }
    },
    showNameInput() {
      this.newNameIsConfirmed = false
      this.nameInputIsVisible = this.user
    },
  }
}
</script>

<style scoped>
  .disabled {
    opacity: 0.7;
  }
</style>
