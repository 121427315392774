<template>
  <div class="reference">
    <vJsonObject
        :editing="user && editing"
        class="jsonObject"
        :class="{editing: editing}"
        :collectionName="collectionName"
        :documentId="documentId"
        :attributeName="attributeName"
        :fields="fields"
    >
      <div class="rendered">
        <div
            v-if="user"
            class="actions"
        >
          <div
              class="icon"
              @click="editing=!editing"
          >
            <leadPencilIcon></leadPencilIcon>
          </div>
          <vDeleteDocumentButton
            @onConfirmed="deleteDocument"
          ></vDeleteDocumentButton>
        </div>
        <vMarkdown :md="citation"></vMarkdown>
      </div>
    </vJsonObject>
  </div>
</template>

<script>
  import JsonDocumentMixin from '../../mixins/documentCollections/json/JsonDocumentMixin'
  export default {
    name: "vAPAArticleInPrePrint",
    mixins: [
      JsonDocumentMixin
    ],
    data() {
      return {
        editing: false,
        notation: '$authors ($publicationDate). _$articleTitle_. $contextName. $permalink',
        fields: [
          {
            label: "Article Title",
            name: "publicationTitle",
            type: "text",
            required: true
          },
          {
            label: "Authors",
            name: 'authors',
            type: 'Authors',
            required: true
          },
          {
            label: "Publisher Name",
            name: 'contextName',
            defaultValue: "PsyArxiv Preprints",
            type: "text",
            required: true
          },
          {
            label: 'Publication Date',
            name: 'publicationDate',
            type: 'date',
            required: true
          },
          {
            name: "Permalink",
            type: "permalink",
            required: true
          },
          {
            name: "Language",
            defaultValue: "en",
            type: "select",
            options: [
              'da',
              'en'
            ]
          },
        ],
        APADateFormat: 'YYYY, MMMM DD'
      }
    },
    computed: {
      reference: {
        get() {
          return this.object
        },
      },
      citation() {

        const authors = this.renderField(this.reference, 'Authors')

        const renderedPubDate = this.$date(this.reference['publicationDate']).format(this.APADateFormat)
        const publicationDate = this.renderField(this.reference, 'Publication Date', renderedPubDate)

        const articleTitle = this.renderField(this.reference, 'Article Title')
        const publisherName = this.renderField(this.reference, 'Publisher Name')

        const permalink = this.reference['Permalink']
          || `<span style="background-color: yellow;">"Permalink" is invalid</span>`

        let citation = this.notation
          .replace(/\$authors/g, authors)
          .replace(/\$publicationDate/g, publicationDate)
          .replace(/\$articleTitle/g, articleTitle)
          .replace(/\$contextName/g, publisherName)
          .replace(/\$permalink/g, permalink)

        return citation
      }
    },
    methods: {
      deleteDocument() {
        this.deleteCollectionDocument(this.documentId)
      },
    }
  }
</script>

<style scoped>
  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>
