<template>
    <div class="doi">
      <div class="prefixedInput">
        <span class="white">{{prefix}}</span><input
            type="text"
            name="name"
            :value="fieldValueSansPrefix"
            @keyup="updateWithPrefixFieldValue"
        >
      </div>
      <div
          class="complaint"
          v-for="(error, index) in complaints"
          :key="index"
      >
        <span class="error">{{error}}</span>
      </div>
    </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  import { prefixes, anyPrefixPattern }   from "@/components/JSONObject/lib/permalinkPrefixes"

  export default {
    name: "vJsonDoiField",
    mixins: [
      JsonFieldMixin
    ],
    data() {
      return {
        checks: [
          {
            validator: (val) => val.match(/[;?@=&"<>#%{}|\\^~[\]`]/) === null,
            complaint: 'the url cannot contain any of the following characters: ; ? @ = & " < > # % { } | \\ ^ ~ [ ] `'
          }
        ],
        prefix: prefixes.doi,
      }
    },
    computed: {
      fieldValueSansPrefix() {
        let fieldValue = this.fieldValue ?? ''
        if (fieldValue) {
          fieldValue = fieldValue.replace(anyPrefixPattern, '')
        }
        return fieldValue
      }
    },
    methods: {
      updateWithPrefixFieldValue(event) {
        const eventClone = {
          ...event,
          target: {
            value: `${this.prefix}${event.target.value}`
          }
        }
        this.onKey(eventClone)
      }
    },
  }
</script>

<style scoped>
  .white {
    background-color: white;
    padding-left: 2px;
  }
  .prefixedInput {
    display: grid;
    grid-template-columns: min-content auto;
  }
</style>
