<template>
  <div
    class="markdownContent"
  >

    <div
        v-if="user && enableEditor"
        class="actions"
    >
      <div class="toggle">
        <LeadPencilIcon
            v-if="!editorIsVisible"
            class="primary"
            title="edit content"
            @click="showEditor"
        ></LeadPencilIcon>
        <EyeOffIcon
            v-if="editorIsVisible"
            class="primary"
            title="hide editor"
            @click="hideEditor"
        ></EyeOffIcon>
      </div>
    </div>

    <div
        class="markdown"
        :class="{editing: editorIsVisible}"
    >
      <vMarkdownInput
        class="in"
        v-if="editorIsVisible"
        :value="value"
        @onChange="relayChange"
      ></vMarkdownInput>
      <vMarkdownOutput
        class="out"
        :value="value"
      ></vMarkdownOutput>

    </div>

    <div
        v-show="error"
        class="error">
      {{error}}
    </div>
  </div>

</template>

<script>
  import WarningInterceptor       from "../../lib/WarningInterceptor"
  import DocumentCollectionsMixin from '../../../../mixins/documentCollections/DocumentCollectionsMixin'
  import vMarkdownInput           from '../vMarkdownInput'
  import vMarkdownOutput          from '../vMarkdownOutput'
  export default {
    name: "vMarkdownContent",
    components: {
      vMarkdownInput,
      vMarkdownOutput,
    },
    mixins: [
      DocumentCollectionsMixin
    ],
    props: {
      enableEditor: {
        type: Boolean,
        default: false
      },
      value: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        editorIsVisible: false,
        error: '',
      }
    },
    watch: {
      value: function() {
        this.error = ''
      }
    },
    mounted() {
      WarningInterceptor.registerErrorCallback(this._uid, this.showError)
    },
    methods: {
      relayChange(newValue) {
        this.$emit('onChange', newValue)
      },
      showError(errorMessage) {
        this.error = errorMessage
        //this.flashError(escapeHTML(errorMessage))
        //console.log('error', errorMessage)
      },
      showEditor() { this.editorIsVisible = this.user && this.enableEditor},
      hideEditor() { this.editorIsVisible = false },
    },

  }
</script>

<style scoped>
  .markdownContent {
    text-align: left;
  }
  .markdown {
    display: grid;
    grid-template-columns: auto;
    text-align: left;
    background-color: transparent;
  }
  .markdown.editing {
    height: 100%;
    width: 100%;
  }
  .markdown.editing .out {
    background-color: transparent;
    height: 100%;
    margin-left: 1rem;
  }
  .markdown.editing .in {
    background-color: white;
    font-size: 100%;
    width: 99%;
    height: 100%;
    max-width: 99%;
    min-width: 50%;
    margin-right: 1rem;
  }
  .error {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #fd7c7c;
    color: black;
    font-family: monospace;
    white-space: pre-wrap;
  }
</style>
