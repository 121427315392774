<template>
  <div class="teaser">
    <div
        class="actions fw"
        v-if="user"
    >
      <vButton
          label="New Full-Width Teaser"
          :onClick="createSingleColumnDocument"
      />
      <vButton
          label="New 2-Column Teaser"
          :onClick="createTwoColumnDocument"
      />
      <vButton
          label="New 3-Column Teaser"
          :onClick="createThreeColumnDocument"
      />
    </div>
    <div
        v-if="teasers.length"
        class="list"
    >
      <vColumnDocument
          class="item"
          v-for="(document, index) in teasers"
          :key="index"
          :collectionName="collectionName"
          :documentName="document.name"
      ></vColumnDocument>
    </div>
    <p
        v-else
        class="icon2x"
    >
      No teasers here yet...
    </p>

  </div>
</template>

<script>
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: "vDocumentTeasers",
    mixins: [
      DocumentCollectionsMixin,
    ],
    props: {
      collectionName: {
        type: String,
        default: 'documentTeasers'
      }
    },
    computed: {
      sortedDocuments: {
        get() {
          let documents = []

          if (this.documents) {
            documents = Object.values(this.documents)
            documents = documents.sort(this.byDescendingIndex)
          }

          return documents
        }
      },
      teasers() {
        return this.sortedDocuments
      }
    },
    mounted() {
      this.loadLatestDocumentVersions(this.collectionName)
    },
    methods: {
      createSingleColumnDocument() {
        const content = [
          {content: 'one'},
        ]
        this.createDocument({
          collectionName: this.collectionName,
          content: JSON.stringify(content),
          type: 'json',
          name: `hopepageTeasers_${Date.now()}`
        })
      },
      createTwoColumnDocument() {
        const content = [
          {content: 'one'},
          {content: 'other'}
        ]
        this.createDocument({
          collectionName: this.collectionName,
          content: JSON.stringify(content),
          type: 'json',
          name: `hopepageTeasers_${Date.now()}`
        })
      },
      createThreeColumnDocument() {
        const content = [
          {content: 'one'},
          {content: 'other'},
          {content: 'third'},
        ]
        this.createDocument({
          collectionName: this.collectionName,
          content: JSON.stringify(content),
          type: 'json',
          name: `hopepageTeasers_${Date.now()}`
        })
      },
    }

  }
</script>

<style scoped>
  .teaser img,
  .teaser .image,
  .teaser .frame
  {
    margin: 0!important;
    padding: 0!important;
    width: 100%;
  }
  .teaser .list .item:not(:last-child) {
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
</style>
