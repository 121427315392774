import Vue from "vue";

class WarningInterceptor {
  errors = [];
  errorCallbacks = {};

  constructor() {
    this.oldWarnHandler = Vue.config.warnHandler;
    Vue.config.warnHandler = (msg, vm, trace) => {
      this.patchedWarnHandler(msg, vm, trace);
      if (this.oldWarnHandler) {
        this.oldWarnHandler(msg, vm, trace);
      }
    };
  }

  patchedWarnHandler(msg, vm, trace) {
    // find markdownDocumentElement
    const markdownDocument = vm
      && vm.$parent
      && vm.$parent.$parent
      && vm.$parent.$parent.$parent

    let id
    if (markdownDocument) {
      id = markdownDocument._uid;
    }

    if (/Error compiling template:/.test(msg)) {
      if (this.errorCallbacks[id]) {
        this.errorCallbacks[id](msg, vm, trace);
      }
    }
  }

  registerErrorCallback(id, fn) {
    if (id && typeof fn === "function") {
      this.errorCallbacks[id] = fn;
    }
  }

  unregisterErrorCallback(id) {
    this.errorCallbacks[id] = undefined;
  }

  restore() {
    this.errorCallback = () => {};
  }
}

export default new WarningInterceptor();
