<template>
  <div class="authors field">
    <label for="name">{{ fieldConfig.label || fieldConfig.name }}</label>
    <div>
      <input
          type="text"
          name="name"
          :value="fieldValue"
          @keyup="onKey"
      >
      <div
          class="complaint"
          v-for="(error, index) in complaints"
          :key="index"
      >
        <span class="error">{{error}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import JsonFieldMixin from '../../mixins/documentCollections/json/JsonFieldMixin'
  export default {
    name: "vJsonAuthorsField",
    mixins: [
      JsonFieldMixin
    ],
    data() {
      let rxInitial = `(?:\\p{Lu}\\.)`
      let rxInitials = `${rxInitial}(?:\\s${rxInitial}{1,3})?`
      let rxFamilyName = `\\p{Lu}\\p{Ll}*`
      let rxAuthor = `${rxFamilyName},\\s${rxInitials}`
      const rxAuthors = new RegExp(`^${rxAuthor}(?:,\\s${rxAuthor}){0,100}$`, 'u')

      return {
        checks: [
          {
            validator: (val) => !!val.match(rxAuthors),
            complaint: 'Publications Authors must match pattern "FamilyName, A. B., FamilyName, C.". Whitespaces, punctuation and letter case are significant.'
          }
        ],
      }
    },
  }
</script>

<style scoped>
  input {
    width: 100%;
  }
</style>
