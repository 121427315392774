<template>

    <textarea
      ref="resize"
      class="mitInput markdown-area p-1"
      :style="textareaStyle"
      :value="markdown"
      @keyup="onKey"
      @blur="updateAttribute"
    ></textarea>

</template>

<script>
  import { isNumber }             from '../../../../../lib/getVariableType'
  import DocumentCollectionsMixin from '../../../mixins/documentCollections/DocumentCollectionsMixin'
  export default {
    name: "vMarkdownInput",
    mixins: [
      DocumentCollectionsMixin,
    ],
    props: {
      defaultContent: {
        type: String,
        default() {
            return `#### Markdown
            A lot of text? <em color="blue">A website?</em>
              <a href="https://markdown-it.github.io/" target="_blank">read more here</a>`
        }
      },
      minLines: {
        type: Number,
        default: 12,
        validate: newValue => isNumber(newValue) && newValue > 0
      },
      value: {
        type: String
      }
    },
    data() {
      return {
        originalValue: '',
      }
    },
    computed: {
      markdown() {
        return this.value || this.content
      },
      textareaStyle() {
        return {
          minHeight: `${this.minLines || 1}rem`
        }
      }
    },
    mounted() {
      this.originalValue = this.content
    },
    methods: {
      encode(html) {
        return html.replace(/</g, '&lt;').replace(/>/g, '&gt;')
      },
      decode(text) {
        return text.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
      },
      restoreAttribute() {
        this.content = this.originalValue
      },
      updateAttribute(event) {
        const pushUpdateUpstream = !this.document
        if (pushUpdateUpstream) {
          this.$emit('onChange', event.target.value)
        } else {
          this.patchContent(event.target.value)
        }
      },
      onKey(event) {
        const abortingKeys = [
          27, // escape
        ]
        const restoreValue = abortingKeys.indexOf(event.keyCode) >= 0
        if (restoreValue) {
          this.restoreAttribute()
        } else {
          this.updateAttribute(event)
        }
      }
    }
  }
</script>

<style scoped>
  .mitInput {
    height: 100%;
    resize:vertical!important;
    min-width: 100%;
    white-space: pre-wrap;
    text-wrap: normal;
  }
</style>
