<template>
  <div class="generic mitOutput">
    <v-runtime-template :template="template"></v-runtime-template>
  </div>
</template>

<script>
  import VRuntimeTemplate from 'v-runtime-template'
  import mit from './markdownIt'

  // todo: mixinify below, implemented functionality is redundant with vMarkdownOutput



  export default {
    name: "vMarkdown",
    components: {
      VRuntimeTemplate
    },
    props: {
      md: {
        type: String,
        required: true
      },
      defaultContent: {
        type: String,
      },
    },
    computed: {
      deepLinkUrl() {
        return this.$route
      },
      markdown() {
        let pmd = this.md || this.defaultContent || ''
        pmd = this.markdownPipeline(pmd)
        return pmd
      },
      markup() {
        let html = mit.render(this.markdown)
        html = this.markupPipeline(html)
        return html
      },
      template() {
        return `<div class="md">${this.markup}</div>`
      }
    },
    mounted() {
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
          this.$nextTick(this.scrollToRequestedElement)
        }
      });
      window.onload = () => {
        this.$nextTick(this.scrollToRequestedElement)
      }
      this.$nextTick(this.scrollToRequestedElement)
    },
    updated() {
      this.$nextTick(this.scrollToRequestedElement)
    },
    methods: {
      // todo: mixinify
      markdownPipeline(md) {
        md = this.frameImagesWithSubtitles(md)
        return md
      },
      markupPipeline(html) {
        html = this.augmentHeadingsWithDeepLinkHooks(html)
        return html
      },
      augmentHeadingsWithDeepLinkHooks(md) {
        const rx = `<h([1-6])(\\s[^>]+)?>([\\s\\S]+?)<\\/h\\1>`
        return this.substituteForEach(md, rx, this.generateDeepLinkHeading)
      },
      frameImagesWithSubtitles(md) {
        /*
         * todo: turns all image notations into vImage component notations
         *  - content trailing the image within one whitespace is converted into the image subtitle
         *  - subtitles end on one whitespace char followed by a newline char
         *
         */
        const rxImageStartTag = `<(img|vImage|v-image)`
        const rxElementAttributes = `\\s+([^>]*?)`
        const rxImageEndTag = `(?:\\s*(?:\\/>)|(?:>\\s*<\\/\\1>))`
        const rxMarkupImage = `${rxImageStartTag}${rxElementAttributes}${rxImageEndTag}`
        const rxMarkdownImage = `!\\[([^\\]]*)\\]\\(([^)\\s]+)\\)`
        const rxImage = `(?:(?:${rxMarkupImage})|(?:${rxMarkdownImage}))`
        const rxFewerThanTwoWhitespaceChars = '\\s?'
        const rxText = '(\\S[\\S\\s]*?\\S)'
        const rxSpaceAndNewlineOrEnd = '(?:\\s+\\n|\\s*$|\\n\\s{2})'
        const rxTrailingSubtitleText = `${rxFewerThanTwoWhitespaceChars}${rxText}`
        const rx = `${rxImage}(?:${rxTrailingSubtitleText})?${rxSpaceAndNewlineOrEnd}`

        return this.substituteForEach(md, rx, this.generateImageComponent)
      },
      substituteForEach(md, pattern, getSubstitute) {
        const rx = new RegExp(pattern, 'gi')
        let matchIndex = -1
        md = md.replace(rx, function (...match) {
          matchIndex++
          return getSubstitute(...match, matchIndex)
        })
        return md
      },
      generateImageComponent(matched, tagName, tagAttributes, mdAlt, mdSrc, subtitle, index) {
        const subtitleSlot = subtitle ? `<div class="subtitle">${subtitle}</div>` : ''
        const attributes = tagAttributes || ` alt="${mdAlt}" src="${mdSrc}"`
        return `
  <vImage${attributes} :isOdd="${!!(index%2)}">${subtitleSlot}</vImage>`
      },
      generateDeepLinkHeading(matched, headingNumber, headingAttributes, innerHTML) {
        const title = encodeURIComponent(innerHTML.replace(/(<\/?([^>]+?)>)/g, ''))
        return `
          <div class="heading">
            <h${headingNumber} id="${title}"${headingAttributes || ''}>
              ${innerHTML}
              <span
               id="${title}"
               class="linkCopy"
               @click.stop="copyDeepLinkUrl"
               title="click to copy the url for this heading"
              >
                <linkPlusIcon/>
              </span>
            </h${headingNumber}>
          </div>`
      },
      capitalize(str) {
        return `${str[0].toUpperCase()}${str.substring(1)}`
      },
      getPageUrl() {
        let url = `${window.location.href}`
        if (url.lastIndexOf(this.$route.hash) > 0) {
          url = url.substring(0, url.lastIndexOf(this.$route.hash))
        }
        return url
      },
      getFirstElementIdAscending(el){
        let id = el.getAttribute('id')
        if (!id) {
          id = this.getFirstElementIdAscending(el.parentElement)
        }
        return id
      },
      copyDeepLinkUrl($event) {
        const pageUrl = this.getPageUrl()
        const id = this.getFirstElementIdAscending($event.target)
        const linkHash = `#${id}`
        const linkUrl = `${pageUrl}${linkHash}`

        this.$copyText(linkUrl)
          .then(() => {
            this.flashInfo(`copied deep link url: ${linkUrl}`, {
              timeout: 2000
            })
          })
      },
      scrollToRequestedElement() {
        const url = window.location.href.split('#')
        let id
        if (url.length) {
          id = url[url.length -1]
        }
        if (id[0] !== '/') {
          const element = document.getElementById(id)
          if (element) {
            element.scrollIntoView()
          }
        }
      },
    },

  }
</script>

<style>
  .mitOutput {
    min-height: 1rem;
  }
  .mitOutput .linkCopy {
    font-size: 1.5rem;
    vertical-align: center;
    opacity: 0.4;
  }
  .mitOutput .linkCopy:hover {
    opacity: 1;
  }
  .mitOutput .heading {
    display: grid;
    grid-template-columns: auto ;
  }
</style>
