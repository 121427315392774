<template>
  <div class="newsTeaser">
    <div
        v-if="user"
        class="actions cr">
      <vDeleteDocumentButton
          @onConfirmed="deleteDocument"
      />
    </div>
    <vMarkdownContent
        :value="content"
        :enableEditor="true"
        @onChange="updateContent($event, index)"
    />
  </div>
</template>

<script>
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: "vNewsTeaser",
    mixins: [
        DocumentCollectionsMixin
    ],
    props: {
      collectionName: {
        type: String,
        default: 'news'
      }
    },
    computed: {
      news() {
        let news = []
        if (this.collection) {
          news = Object.values(this.collection)
        }
        return news
      }
    },
    methods: {
      updateContent(newValue) {
        this.patchContent(newValue)
      }
    }
  }
</script>

<style scoped>
  .news {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .feed .post {
    max-width: 38rem;
    margin-block-end: 1em;
  }
  .actions {
    display: inline-block;
    width: 100%;
  }
</style>
