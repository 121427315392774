<template>
  <div class="APACitation citation">
    <component
      class="icon6x"
      :is="contextIcon"
      :title="citation.context"
      fillColor="#FF0000"
    ></component>
    <component
      v-if="citationContextComponentName"
      :is="citationContextComponentName"
      :collectionName="collectionName"
      :documentId="documentId"
    ></component>
    <div
      v-else
      class="error"
    >
      unsupported citation context name: "{{citation.context}}"
    </div>
  </div>
</template>

<script>
  import JsonDocumentMixin        from '../../mixins/documentCollections/json/JsonDocumentMixin'
  import vAPAArticleInPrePrint    from "./vAPAArticleInPrePrint"
  import vAPAWebPage              from "./vAPAWebPage"
  import { capitalize } from '../../../../lib/helpers'

  export default {
    name: "vAPACitation",
    components: {
      vAPAArticleInPrePrint,
      vAPAWebPage,
    },
    mixins: [
      JsonDocumentMixin,
    ],
    props: {
      collectionName: {
        type: String,
        default: 'publications'
      },
      documentId: {
        type: String,
        required: true
      },
    },
    computed: {
      citation: {
        get() {
          return this.object
        },
      },
      citationContextComponentName() {
        let capitalizedContextName = this.capitalize(this.citation.context)
        capitalizedContextName = `vAPA${capitalizedContextName}`
        const componentIsKnown = this.$options.components[capitalizedContextName]
        return componentIsKnown ? capitalizedContextName : false
      },
      contextIcon() {
        const cName = this.citationContextComponentName
        let icon
        if (!cName) {
          icon = 'Help'
        } else if (cName === 'vAPAWebPage') {
          icon = 'Web'
        } else if (cName === 'vAPAArticleInPrePrint') {
          icon = 'CloudPrint'
        }
        return icon + 'Icon'
      }
    },
    methods:{
      capitalize
    }
  }
</script>

<style>
  .APACitation.citation {
    display: grid;
    grid-template-columns: 8em auto;
  }
  .APACitation input {
    width: 100%;
  }
  .APACitation .complaint {
    padding-left: 1rem;
  }
  .APACitation .error {
    color: darkred!important;
  }
</style>
